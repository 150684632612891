/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/events/events.scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************/
.event-date-cal {
  text-align: center;
  line-height: 1.75em;
}
.event-date-cal .event-date-cal-weekday {
  font-size: 14px;
  font-weight: bold;
}
.event-date-cal .event-date-cal-day {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}
.event-date-cal .event-date-cal-month {
  color: #ff0000;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

body.active-light-mode .event-date-cal .event-date-cal-day {
  color: #000;
}

.event-snippet .inner div.content {
  padding: 20px;
}
.event-list * {
  max-height: 300px;
}

.event-list {
  display: flex;
  flex-wrap: wrap;
}
.event-list .event {
  border: none !important;
  border-radius: unset !important;
}
.event-list .event .card-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}
.event-list .event .event-preview {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  text-align: center;
  padding: 0rem 1rem;
}
.event-list .event .event-preview .event-infos {
  display: flex;
  flex-wrap: wrap;
  align-self: end;
  justify-content: center;
  height: -moz-fit-content;
  height: fit-content;
}
.event-list .event .event-preview .event-btns {
  display: flex;
  flex-wrap: nowrap;
  align-self: end;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 1rem;
}
.event-list .event .event-image {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 300px;
}
